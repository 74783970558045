/* ===================================================================
 * # hero
 *
 * ------------------------------------------------------------------- */
 .s-hero {
    background-color: var(--color-gray-18);
    width: 100%;
    height: 100vh;
    min-height: calc(25.5 * var(--space));
    overflow: hidden;
    position: relative;
}

.s-hero__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/header-bg-3000.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.s-hero__bg::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .5;
}

.s-hero__bg::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, black 15%, rgba(0, 0, 0, 0) 100%);
    opacity: .4;
}

/* ------------------------------------------------------------------- 
 * ## hero content
 * ------------------------------------------------------------------- */
.s-hero__content {
    height: 100%;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    padding-top: 16vh;
    padding-bottom: 12vh;
    position: relative;
}

.s-hero__content h1 {
    font-size: 9.8rem;
    font-weight: 600;
    letter-spacing: -.04em;
    line-height: 1;
    color: white;
    margin-top: 0;
    margin-bottom: 0;
}

.s-hero__content h3 {
    font-family: var(--font-2);
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1.667;
    color: rgba(255, 255, 255, 0.5);
    margin-top: var(--vspace-0_5);
    margin-right: auto;
    margin-left: auto;
    padding-bottom: var(--vspace-1);
    max-width: 800px;
    position: relative;
}

.s-hero__content h3 span, .s-hero__content h3 a {
    color: white;
}

.s-hero__content h3::after {
    display: block;
    content: "";
    text-align: center;
    height: 1px;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.05);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    bottom: 0;
    left: 50%;
}

/* ------------------------------------------------------------------- 
 * ## hero social
 * ------------------------------------------------------------------- */
.s-hero__content-social {
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    font-size: 2.6rem;
    line-height: 1;
    margin-top: var(--vspace-0_5);
}

.s-hero__content-social a {
    color: white;
    margin-right: var(--vspace-0_75);
}

.s-hero__content-social a:hover, 
.s-hero__content-social a:focus {
    color: var(--color-2-light);
}

.s-hero__content-social a:last-child {
    margin-right: 0;
}

/* ------------------------------------------------------------------- 
 * ## hero scrolldown
 * ------------------------------------------------------------------- */
.s-hero__scroll {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: var(--vspace-1_25);
}

.s-hero__scroll-link {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    font-family: var(--font-1);
    font-weight: 400;
    font-size: calc(var(--text-size) * 0.6111);
    line-height: var(--vspace-1);
    text-transform: uppercase;
    letter-spacing: .4em;
    color: white;
}

.s-hero__scroll-link:hover .scroll-text::after, 
.s-hero__scroll-link:focus .scroll-text::after {
    width: 100%;
}

.s-hero__scroll-link .scroll-arrow {
    display: block;
    width: var(--vspace-1);
    height: var(--vspace-1);
    margin-right: 1.6rem;
    border-radius: 50%;
    border: 2px solid white;
    position: relative;
}

.s-hero__scroll-link .scroll-arrow svg {
    height: 2rem;
    width: 2rem;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    left: 50%;
    top: 50%;
}

.s-hero__scroll-link .scroll-arrow svg path {
    fill: white;
}

.s-hero__scroll-link .scroll-text {
    position: relative;
}

.s-hero__scroll-link .scroll-text::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background-color: white;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
}

/* -------------------------------------------------------------------
 * ## animate hero content
 * ------------------------------------------------------------------- */
.ss-preload .s-header, 
.ss-preload .s-hero {
    visibility: hidden;
    opacity: 0;
}
.ss-preload .s-hero__content {
    opacity: 0;
}
.ss-loaded .s-header,
.ss-loaded .s-hero {
    visibility: visible;
    opacity: 1;
}
.ss-loaded .s-hero__content {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * hero
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1600px) {
    .s-hero__content h1 {
        font-size: 9.4rem;
    }
    .s-hero__content h3 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 1400px) {
    .s-hero__content h1 {
        font-size: 8.8rem;
    }
    .s-hero__content h3 {
        font-size: 2rem;
        max-width: 680px;
    }
}

@media screen and (max-width: 1200px) {
    .s-hero__content h1 {
        font-size: 8.4rem;
    }
    .s-hero__content h3 {
        font-size: 1.9rem;
    }
}

@media screen and (max-width: 900px) {
    .s-hero__content h1 {
        font-size: 7.6rem;
    }
    .s-hero__content h3 {
        font-size: 1.8rem;
    }
    .s-hero__content-social {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 800px) {
    .s-hero__content {
        padding-top: 12vh;
    }
    .s-hero__content h1 {
        font-size: 6.8rem;
    }
    .s-hero__content h3 {
        max-width: 600px;
    }
}

@media screen and (max-width: 700px) {
    .s-hero__content h1 {
        font-size: 6.2rem;
    }
}

@media screen and (max-width: 600px) {
    .s-hero__content h1 {
        font-size: 5.8rem;
    }
    .s-hero__content-social {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 500px) {
    .s-hero__content h1 {
        font-size: 5.2rem;
    }
}

@media screen and (max-width: 400px) {
    .s-hero__content h1 {
        font-size: 4.8rem;
    }
    .s-hero__content h3 {
        font-size: 1.7rem;
    }
    .s-hero__content h3::after {
        width: 240px;
    }
    .s-hero__content-social {
        font-size: 2rem;
    }
    .s-hero__content-social a {
        margin-right: var(--vspace-0_5);
    }
}

@media screen and (max-width: 360px) {
    .s-hero__scroll-link .scroll-arrow {
        display: none;
    }
}

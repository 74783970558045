/* ------------------------------------------------------------------- 
 * ## vertical spacing and typescale
 * ------------------------------------------------------------------- */
 :root {

    /* spacing
     * base font size: 18px 
     * vertical space unit : 32px
     */
    --base-size: 62.5%;
    --base-font-size: 1.8rem;
    --space: 3.2rem;

    /* vertical spacing 
     */
    --vspace-0_125: calc(0.125 * var(--space));
    --vspace-0_25 : calc(0.25 * var(--space));
    --vspace-0_5  : calc(0.5 * var(--space));
    --vspace-0_75 : calc(0.75 * var(--space));
    --vspace-0_875: calc(0.875 * var(--space));
    --vspace-1    : calc(var(--space));
    --vspace-1_25 : calc(1.25 * var(--space));
    --vspace-1_5  : calc(1.5 * var(--space));
    --vspace-1_75 : calc(1.75 * var(--space));
    --vspace-2    : calc(2 * var(--space));
    --vspace-2_5  : calc(2.5 * var(--space));
    --vspace-3    : calc(3 * var(--space));
    --vspace-3_5  : calc(3.5 * var(--space));
    --vspace-4    : calc(4 * var(--space));
    --vspace-4_5  : calc(4.5 * var(--space));
    --vspace-5    : calc(5 * var(--space));

    /* type scale
     * ratio 1:2 | base: 18px
     * -------------------------------------------------------
     *
     * --text-display-3 = (77.40px)
     * --text-display-2 = (64.50px)
     * --text-display-1 = (53.75px)
     * --text-xxxl      = (44.79px)
     * --text-xxl       = (37.32px)
     * --text-xl        = (31.10px)
     * --text-lg        = (25.92px)
     * --text-md        = (21.60px)
     * --text-size      = (18.00px) BASE
     * --text-sm        = (15.00px)
     * --text-xs        = (12.50px)
     *
     * -------------------------------------------------------
     */
    --text-scale-ratio: 1.2;
    --text-size       : var(--base-font-size);
    --text-xs         : calc((var(--text-size) / var(--text-scale-ratio)) / var(--text-scale-ratio));
    --text-sm         : calc(var(--text-xs) * var(--text-scale-ratio));
    --text-md         : calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-lg         : calc(var(--text-md) * var(--text-scale-ratio));
    --text-xl         : calc(var(--text-lg) * var(--text-scale-ratio));
    --text-xxl        : calc(var(--text-xl) * var(--text-scale-ratio));
    --text-xxxl       : calc(var(--text-xxl) * var(--text-scale-ratio));
    --text-display-1  : calc(var(--text-xxxl) * var(--text-scale-ratio));
    --text-display-2  : calc(var(--text-display-1) * var(--text-scale-ratio));
    --text-display-3  : calc(var(--text-display-2) * var(--text-scale-ratio));

    /* default button height
     */
    --vspace-btn: var(--vspace-2);
}

/* on mobile devices below 600px
 */
@media screen and (max-width: 600px) {
    :root {
        --base-font-size: 1.6rem;
        --space: 2.8rem;
    }
}

/* ===================================================================
 * # Grid v3.0.0
 *
 *   -----------------------------------------------------------------
 * - Grid breakpoints are based on MAXIMUM WIDTH media queries, 
 *   meaning they apply to that one breakpoint and ALL THOSE BELOW IT.
 * - Grid columns without a specified width will automatically layout 
 *   as equal width columns.
 * ------------------------------------------------------------------- */

/* rows
 * ------------------------------------- */
 .row {
    width: 92%;
    max-width: var(--width-grid-max);
    margin: 0 auto;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
}

.row .row {
    width: auto;
    max-width: none;
    margin-left: calc(var(--gutter-lg) * -1);
    margin-right: calc(var(--gutter-lg) * -1);
}

/* columns
 * -------------------------------------- */
.column {
    -ms-flex: 1 1 0%;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    padding: 0 var(--gutter-lg);
}

.collapse>.column, .column.collapse {
    padding: 0;
}

/* flex row containers utility classes
 */
.row.row-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.row.row-nowrap {
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
}

.row.row-y-top {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
}

.row.row-y-bottom {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
}

.row.row-y-center {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
}

.row.row-stretch {
    -ms-flex-align: stretch;
    -webkit-box-align: stretch;
    align-items: stretch;
}

.row.row-baseline {
    -ms-flex-align: baseline;
    -webkit-box-align: baseline;
    align-items: baseline;
}

.row.row-x-left {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.row.row-x-right {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.row.row-x-center {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
}

/* flex item utility alignment classes
 */
.align-center {
    margin: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.align-left {
    margin-right: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.align-right {
    margin-left: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.align-x-center {
    margin-right: auto;
    margin-left: auto;
}

.align-x-left {
    margin-right: auto;
}

.align-x-right {
    margin-left: auto;
}

.align-y-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.align-y-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.align-y-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

/* large screen column widths 
 */
.large-1 {
    -ms-flex: 0 0 8.33333%;
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.large-2 {
    -ms-flex: 0 0 16.66667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.large-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

.large-4 {
    -ms-flex: 0 0 33.33333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.large-5 {
    -ms-flex: 0 0 41.66667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.large-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.large-7 {
    -ms-flex: 0 0 58.33333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.large-8 {
    -ms-flex: 0 0 66.66667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.large-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
}

.large-10 {
    -ms-flex: 0 0 83.33333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.large-11 {
    -ms-flex: 0 0 91.66667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.large-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

/* ------------------------------------------------------------------- 
 * ## medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {
    .row .row {
        margin-left: calc(var(--gutter-md) * -1);
        margin-right: calc(var(--gutter-md) * -1);
    }
    .column {
        padding: 0 var(--gutter-md);
    }
    .medium-1 {
        -ms-flex: 0 0 8.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .medium-2 {
        -ms-flex: 0 0 16.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .medium-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .medium-4 {
        -ms-flex: 0 0 33.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .medium-5 {
        -ms-flex: 0 0 41.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .medium-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .medium-7 {
        -ms-flex: 0 0 58.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .medium-8 {
        -ms-flex: 0 0 66.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .medium-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .medium-10 {
        -ms-flex: 0 0 83.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .medium-11 {
        -ms-flex: 0 0 91.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .medium-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* ------------------------------------------------------------------- 
 * ## tablets
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
    .row {
        width: 90%;
    }
    .tab-1 {
        -ms-flex: 0 0 8.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .tab-2 {
        -ms-flex: 0 0 16.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .tab-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .tab-4 {
        -ms-flex: 0 0 33.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .tab-5 {
        -ms-flex: 0 0 41.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .tab-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .tab-7 {
        -ms-flex: 0 0 58.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .tab-8 {
        -ms-flex: 0 0 66.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .tab-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .tab-10 {
        -ms-flex: 0 0 83.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .tab-11 {
        -ms-flex: 0 0 91.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .tab-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .hide-on-tablet {
        display: none;
    }
}

/* ------------------------------------------------------------------- 
 * ## mobile devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {
    .row {
        width: 100%;
        padding-left: 6vw;
        padding-right: 6vw;
    }
    .row .row {
        margin-left: calc(var(--gutter-mob) * -1);
        margin-right: calc(var(--gutter-mob) * -1);
        padding-left: 0;
        padding-right: 0;
    }
    .column {
        padding: 0 var(--gutter-mob);
    }
    .mob-1 {
        -ms-flex: 0 0 8.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .mob-2 {
        -ms-flex: 0 0 16.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .mob-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .mob-4 {
        -ms-flex: 0 0 33.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .mob-5 {
        -ms-flex: 0 0 41.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .mob-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .mob-7 {
        -ms-flex: 0 0 58.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .mob-8 {
        -ms-flex: 0 0 66.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .mob-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .mob-10 {
        -ms-flex: 0 0 83.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .mob-11 {
        -ms-flex: 0 0 91.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .mob-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .hide-on-mobile {
        display: none;
    }
}

/* ------------------------------------------------------------------- 
 * ## small mobile devices <= 400px
 * ------------------------------------------------------------------- */
@media screen and (max-width: 400px) {
    .row .row {
        margin-left: 0;
        margin-right: 0;
    }
    .column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }
    .hide-on-mobile-small {
        display: none;
    }
}


/* ===================================================================
 * # block grids
 *
 * -------------------------------------------------------------------
 * Equally-sized columns define at parent/row level.
 * ------------------------------------------------------------------- */
.block-large-1-8>.column {
    -ms-flex: 0 0 12.5%;
    -webkit-box-flex: 0;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.block-large-1-6>.column {
    -ms-flex: 0 0 16.66667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.block-large-1-5>.column {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%;
}

.block-large-1-4>.column {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

.block-large-1-3>.column {
    -ms-flex: 0 0 33.33333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.block-large-1-2>.column {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.block-large-full>.column {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

/* ------------------------------------------------------------------- 
 * ## block grids - medium screen devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1200px) {
    .block-medium-1-8>.column {
        -ms-flex: 0 0 12.5%;
        -webkit-box-flex: 0;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .block-medium-1-6>.column {
        -ms-flex: 0 0 16.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .block-medium-1-5>.column {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .block-medium-1-4>.column {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .block-medium-1-3>.column {
        -ms-flex: 0 0 33.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .block-medium-1-2>.column {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .block-medium-full>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* ------------------------------------------------------------------- 
 * ## block grids - tablets
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
    .block-tab-1-8>.column {
        -ms-flex: 0 0 12.5%;
        -webkit-box-flex: 0;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .block-tab-1-6>.column {
        -ms-flex: 0 0 16.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .block-tab-1-5>.column {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .block-tab-1-4>.column {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .block-tab-1-3>.column {
        -ms-flex: 0 0 33.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .block-tab-1-2>.column {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .block-tab-full>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* ------------------------------------------------------------------- 
 * ## block grids - mobile devices
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {
    .block-mob-1-8>.column {
        -ms-flex: 0 0 12.5%;
        -webkit-box-flex: 0;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .block-mob-1-6>.column {
        -ms-flex: 0 0 16.66667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .block-mob-1-5>.column {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .block-mob-1-4>.column {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .block-mob-1-3>.column {
        -ms-flex: 0 0 33.33333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .block-mob-1-2>.column {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .block-mob-full>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* ------------------------------------------------------------------- 
 * ## block grids - small mobile devices <= 400px
 * ------------------------------------------------------------------- */
@media screen and (max-width: 400px) {
    .stack>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }
}

/* ===================================================================
 * # custom grid, block grid STACK breakpoints
 *
 * ------------------------------------------------------------------- */
 @media screen and (max-width: 1000px) {
    .w-1000-stack, .block-1000-stack>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .w-700-stack, .block-700-stack>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .w-500-stack, .block-500-stack>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/* ===================================================================
 * # MISC 
 *
 * ------------------------------------------------------------------- */
 .h-group:after {
    content: "";
    display: table;
    clear: both;
}

/* misc helper classes
 */
.is-hidden {
    display: none;
}

.is-invisible {
    visibility: hidden;
}

.h-screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    border: 0;
    position: absolute;
}

.h-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.h-overflow-hidden {
    overflow: hidden;
}

.h-remove-top {
    margin-top: 0;
}

.h-remove-bottom {
    margin-bottom: 0;
}

.h-add-half-bottom {
    margin-bottom: var(--vspace-0_5) !important;
}

.h-add-bottom {
    margin-bottom: var(--vspace-1) !important;
}

.h-no-border {
    border: none;
}

.h-full-width {
    width: 100%;
}

.h-text-center {
    text-align: center;
}

.h-text-left {
    text-align: left;
}

.h-text-right {
    text-align: right;
}

.h-pull-left {
    float: left;
}

.h-pull-right {
    float: right;
}
/* =================================================================== 
 * # additional components
 *
 * ------------------------------------------------------------------- */

/* ------------------------------------------------------------------- 
 * ## additional typo styles - (_additional-typo.scss)
 * ------------------------------------------------------------------- */
 .drop-cap:first-letter {
    float: left;
    font-family: var(--font-1);
    font-weight: 700;
    font-size: calc(3 * var(--space));
    line-height: 1;
    padding: 0 0.125em 0 0;
    text-transform: uppercase;
    background: transparent;
    color: var(--color-text-dark);
}

/* line definition style 
 * ----------------------------------------------- */
.lining dt, .lining dd {
    display: inline;
    margin: 0;
}

.lining dt+dt:before, .lining dd+dt:before {
    content: "\A";
    white-space: pre;
}

.lining dd+dd:before {
    content: ", ";
}

.lining dd+dd:before {
    content: ", ";
}

.lining dd:before {
    content: ": ";
    margin-left: -0.2em;
}

/* dictionary definition style 
 * ----------------------------------------------- */
.dictionary-style dt {
    display: inline;
    counter-reset: definitions;
}

.dictionary-style dt+dt:before {
    content: ", ";
    margin-left: -0.2em;
}

.dictionary-style dd {
    display: block;
    counter-increment: definitions;
}

.dictionary-style dd:before {
    content: counter(definitions, decimal) ". ";
}

/** 
 * Pull Quotes
 * -----------
 * markup:
 *
 * <figure class="pull-quote">
 *		<blockquote>
 *			<p></p>
 *		</blockquote>
 * </figure>
 *
 * --------------------------------------------------------------------- */
.pull-quote {
    position: relative;
    padding: 0;
    margin-top: 0;
    text-align: center;
    background-color: var(--color-gray-1);
}

.pull-quote blockquote {
    border: none;
    margin: 0 auto;
    max-width: 62rem;
    padding-top: var(--vspace-4);
    padding-bottom: var(--vspace-2);
    position: relative;
}

.pull-quote blockquote p {
    font-weight: 400;
    color: var(--color-text-dark);
}

.pull-quote blockquote:before {
    content: "";
    display: block;
    height: var(--vspace-1_25);
    width: var(--vspace-1_25);
    background-repeat: no-repeat;
    background: center center;
    background-size: contain;
    background-image: url(../assets/images/icons/icon-quote.svg);
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    top: var(--vspace-1_5);
    left: 50%;
}

/** 
 * Stats Tab
 * ---------
 * markup:
 *
 * <ul class="stats-tabs">
 *		<li><a href="#">[value]<em>[name]</em></a></li>
 *	</ul>
 *
 * Extend this object into your markup.
 *
 * --------------------------------------------------------------------- */
.stats-tabs {
    padding: 0;
    margin: var(--vspace-1) 0;
}

.stats-tabs li {
    display: inline-block;
    margin: 0 1.6rem var(--vspace-0_5) 0;
    padding: 0 1.5rem 0 0;
    border-right: 1px solid var(--color-border);
}

.stats-tabs li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.stats-tabs li a {
    display: inline-block;
    font-size: var(--text-lg);
    font-family: var(--font-1);
    font-weight: 600;
    line-height: var(--vspace-1_5);
    border: none;
    color: var(--color-black);
}

.stats-tabs li a:hover {
    color: var(--color-1);
}

.stats-tabs li a em {
    display: block;
    margin: 0;
    font-family: var(--font-1);
    font-size: var(--text-sm);
    line-height: var(--vspace-0_5);
    font-weight: normal;
    font-style: normal;
    color: var(--color-text-light);
}

/* ------------------------------------------------------------------- 
 * ## skillbars
 * ------------------------------------------------------------------- */
.skill-bars-fat {
    list-style: none;
    margin: var(--vspace-2) 0 var(--vspace-1);
}

.skill-bars-fat li {
    height: 5.2rem;
    background: transparent;
    margin-bottom: 5.2rem;
    padding: 0;
    position: relative;
}

.skill-bars-fat li strong {
    font-family: var(--font-1);
    font-weight: 600;
    color: var(--color-text-dark);
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: calc(var(--text-size) * 0.7778);
    line-height: 2rem;
    position: absolute;
    top: -3.2rem;
    left: 0;
}

.skill-bars-fat li .progress {
    background: var(--color-gray-3);
    position: relative;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}

.skill-bars-fat li .progress::before {
    content: "";
    display: block;
    height: 100%;
    background-color: var(--color-gray-18);
}

.skill-bars-fat li .percent5::before {
    width: 5%;
}

.skill-bars-fat li .percent10::before {
    width: 10%;
}

.skill-bars-fat li .percent15::before {
    width: 15%;
}

.skill-bars-fat li .percent20::before {
    width: 20%;
}

.skill-bars-fat li .percent25::before {
    width: 25%;
}

.skill-bars-fat li .percent30::before {
    width: 30%;
}

.skill-bars-fat li .percent35::before {
    width: 35%;
}

.skill-bars-fat li .percent40::before {
    width: 40%;
}

.skill-bars-fat li .percent45::before {
    width: 45%;
}

.skill-bars-fat li .percent50::before {
    width: 50%;
}

.skill-bars-fat li .percent55::before {
    width: 55%;
}

.skill-bars-fat li .percent60::before {
    width: 60%;
}

.skill-bars-fat li .percent65::before {
    width: 65%;
}

.skill-bars-fat li .percent70::before {
    width: 70%;
}

.skill-bars-fat li .percent75::before {
    width: 75%;
}

.skill-bars-fat li .percent80::before {
    width: 80%;
}

.skill-bars-fat li .percent85::before {
    width: 85%;
}

.skill-bars-fat li .percent90::before {
    width: 90%;
}

.skill-bars-fat li .percent95::before {
    width: 95%;
}

.skill-bars-fat li .percent100::before {
    width: 100%;
}

/* ------------------------------------------------------------------- 
 * ## alert box
 * ------------------------------------------------------------------- */
.alert-box {
    padding: var(--vspace-0_75) 4rem var(--vspace-0_75) 3.2rem;
    margin-bottom: var(--vspace-1);
    border-radius: 4px;
    font-family: var(--font-1);
    font-weight: 500;
    font-size: var(--text-sm);
    line-height: var(--vspace-0_75);
    opacity: 1;
    visibility: visible;
    position: relative;
}

.alert-box__close {
    position: absolute;
    display: block;
    right: 1.6rem;
    top: 1.6rem;
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.alert-box__close::before, 
.alert-box__close::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 12px;
    top: 0;
    left: 5px;
}

.alert-box__close::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.alert-box__close::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.alert-box--error {
    background-color: var(--color-error);
    color: var(--color-error-content);
}

.alert-box--error .alert-box__close::before, 
.alert-box--error .alert-box__close::after {
    background-color: var(--color-error-content);
}

.alert-box--success {
    background-color: var(--color-success);
    color: var(--color-success-content);
}

.alert-box--success .alert-box__close::before, 
.alert-box--success .alert-box__close::after {
    background-color: var(--color-success-content);
}

.alert-box--info {
    background-color: var(--color-info);
    color: var(--color-info-content);
}

.alert-box--info .alert-box__close::before, 
.alert-box--info .alert-box__close::after {
    background-color: var(--color-info-content);
}

.alert-box--notice {
    background-color: var(--color-notice);
    color: var(--color-notice-content);
}

.alert-box--notice .alert-box__close::before, 
.alert-box--notice .alert-box__close::after {
    background-color: var(--color-notice-content);
}

.alert-box.hideit {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .5s;
    transition: all .5s;
}

/* -------------------------------------------------------------------
 * ## pagination 
 * ------------------------------------------------------------------- */
.pgn {
    --pgn-num-height: calc(var(--vspace-1) + .4rem);
    margin: var(--vspace-1) auto var(--vspace-1);
    text-align: center;
}

.pgn ul {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    position: relative;
    padding: 0 6rem;
}

.pgn ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.pgn__num {
    font-family: var(--font-1);
    font-weight: 600;
    font-size: var(--text-size);
    line-height: var(--vspace-1);
    display: inline-block;
    padding: .2rem 1.2rem;
    height: var(--pgn-num-height);
    margin: .2rem .2rem;
    color: var(--color-text-dark);
    border-radius: 4px;
    -webkit-transition: all, .3s, ease-in-out;
    transition: all, .3s, ease-in-out;
}

.pgn__num:hover {
    background: var(--color-gray-18);
    color: var(--color-white);
}

.pgn .current, .pgn .current:hover {
    background-color: var(--color-gray-18);
    color: var(--color-white);
}

.pgn .inactive, .pgn .inactive:hover {
    opacity: 0.4;
    cursor: default;
}

.pgn__prev, .pgn__next {
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    height: var(--pgn-num-height);
    width: 4.8rem;
    line-height: var(--vspace-1);
    border-radius: 4px;
    padding: 0;
    margin: 0;
    opacity: 1;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    -webkit-transition: all, .3s, ease-in-out;
    transition: all, .3s, ease-in-out;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}

.pgn__prev:hover, .pgn__next:hover {
    background-color: var(--color-gray-18);
}

.pgn__prev svg, .pgn__next svg {
    height: 2.4rem;
    width: 2.4rem;
    -webkit-transition: all, .3s, ease-in-out;
    transition: all, .3s, ease-in-out;
}

.pgn__prev svg path, .pgn__next svg path {
    fill: var(--color-text-dark);
}

.pgn__prev:hover svg path, 
.pgn__prev:focus svg path, 
.pgn__next:hover svg path, 
.pgn__next:focus svg path {
    fill: white;
}

.pgn__prev {
    left: 0;
}

.pgn__next {
    right: 0;
}

.pgn__prev.inactive, 
.pgn__next.inactive {
    opacity: 0.4;
    cursor: default;
}

.pgn__prev.inactive:hover, 
.pgn__next.inactive:hover {
    background-color: transparent;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * pagination
 * ------------------------------------------------------------------- */
@media screen and (max-width: 600px) {
    .pgn ul {
        padding: 0 5.2rem;
    }
}


/* ===================================================================
 * # common and reusable styles
 *
 * ------------------------------------------------------------------- */
.wide {
    max-width: var(--width-wide);
}

.narrow {
    max-width: var(--width-narrow);
}

.sticky-block {
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
}

.section-header-allcaps {
    display: inline-block;
    font-size: var(--text-size);
    line-height: var(--vspace-1_25);
    text-transform: uppercase;
    letter-spacing: .25em;
    padding-left: .25em;
    padding-bottom: .1em;
    margin-top: 0;
    margin-bottom: var(--vspace-1_5);
    position: relative;
}

.section-header-allcaps:after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--color-1);
    position: absolute;
    left: 0;
    bottom: 0;
}

/* ------------------------------------------------------------------- 
 * ## animation stuff 
 * ------------------------------------------------------------------- */
.animate-this {
    opacity: 0;
    visibility: hidden;
}

.no-js .animate-this, 
.no-cssanimations .animate-this {
    opacity: 1;
    visibility: visible;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}

/* fade in up */
@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
    to {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }
    to {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

/* ------------------------------------------------------------------- 
 * ## MailtoUI style overrides
 * ------------------------------------------------------------------- */
.mailtoui-modal {
    --mailtoui-modal-head-bgcolor: var(--color-gray-18);
    --mailtoui-modal-head-title-color: white;
    --mailtoui-modal-body-bgcolor: var(--color-gray-14);
    --mailtoui-button-bgcolor: var(--color-gray-16);
    --mailtoui-button-text-color: white;
    --mailtoui-button-bgcolor-hover: var(--color-2);
    --mailtoui-button-text-color-hover: white;
    --mailtoui-button-text-size: 16px;
    --mailtoui-email-address-bgcolor: var(--color-gray-10);
    --mailtoui-email-address-text-color: black;
    font-family: var(--font-1);
    background-color: #151515;
    color: white;
}

.mailtoui-modal-content {
    background-color: var(--mailtoui-modal-body-bgcolor);
    border-radius: var(--border-radius);
}

.mailtoui-modal-content button {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.mailtoui-modal-head {
    background-color: var(--mailtoui-modal-head-bgcolor);
}

.mailtoui-modal-title {
    font-family: var(--font-1);
    font-weight: 400;
    font-size: 16px;
    color: var(--mailtoui-modal-head-title-color);
}

.mailtoui-modal-close {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    font-size: 32px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.5);
}

.mailtoui-modal-close:hover, .mailtoui-modal-close:focus {
    color: white;
    font-weight: 300;
}

.mailtoui-modal-body {
    background-color: var(--mailtoui-modal-body-bgcolor);
}

.mailtoui-button-text {
    font-size: var(--mailtoui-button-text-size);
    text-transform: none;
    letter-spacing: 0;
}

.mailtoui-button:focus .mailtoui-button-content {
    background-color: var(--color-2);
    color: white;
}

.mailtoui-button-content, .mailtoui-button-copy {
    background-color: var(--mailtoui-button-bgcolor);
    color: var(--mailtoui-button-text-color);
    border-radius: var(--border-radius);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.mailtoui-button-content:hover, 
.mailtoui-button-content:focus, 
.mailtoui-button-copy:hover, 
.mailtoui-button-copy:focus {
    background-color: var(--mailtoui-button-bgcolor-hover);
    color: var(--mailtoui-button-text-color-hover);
}

.mailtoui-copy {
    border-radius: var(--border-radius);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.mailtoui-button-icon-copy {
    line-height: var(--vspace-1);
}

.mailtoui-button-copy-clicked, 
.mailtoui-button-copy-clicked:hover, 
.mailtoui-button-copy-clicked:focus {
    background-color: #1F9D55;
    color: white;
}

.mailtoui-email-address {
    background-color: var(--mailtoui-email-address-bgcolor);
    color: var(--mailtoui-email-address-text-color);
    font-size: 16px;
    line-height: 1;
    border-radius: var(--border-radius);
}

.mailtoui-brand a {
    color: rgba(255, 255, 255, 0.4);
}

.mailtoui-brand a:hover, .mailtoui-brand a:focus {
    font-weight: 400;
    color: white;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * common and reusable styles
 * ------------------------------------------------------------------- */
@media screen and (max-width: 1000px) {
    .section-header-allcaps {
        font-size: calc(var(--text-size) * 0.9444);
    }
}

@media screen and (max-width: 900px) {
    .section-header-allcaps {
        font-size: calc(var(--text-size) * 0.8889);
    }
}

@media screen and (max-width: 800px) {
    .section-header-allcaps {
        font-size: var(--text-size);
        text-align: center;
        margin-bottom: calc(2.75 * var(--space));
    }
}


@import '../../styles/vendor';

/* ===================================================================
 * # testimonials
 *
 * ------------------------------------------------------------------- */
 .s-testimonials {
    padding-top: var(--vspace-5);
    padding-bottom: var(--vspace-3_5);
    color: rgba(255, 255, 255, 0.8);
    background-color: var(--color-gray-19);
    overflow: hidden;
    position: relative;
}

.s-testimonials__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/testimonials-bg-3000.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.s-testimonials__bg::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-19);
    opacity: .8;
}

.s-testimonials h3 {
    margin-top: 0;
    color: white;
}

.s-testimonials__header, .s-testimonials__content {
    position: relative;
}

.s-testimonials__header {
    text-align: center;
    margin-bottom: var(--vspace-2);
}

.s-testimonials__content>.column:first-child {
    min-height: 0;
    min-width: 0;
}

/* ------------------------------------------------------------------- 
 * ## swiper style overrides
 * ------------------------------------------------------------------- */
.s-testimonials .swiper-container {
    padding-bottom: var(--vspace-2_5);
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 9px;
}

.s-testimonials .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: white;
    opacity: 0.4;
}

.s-testimonials .swiper-pagination-bullet-active {
    background: var(--color-1);
    opacity: 1;
}

.s-testimonials .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;

    &.center {
        justify-content: center;
    }
}
/* ------------------------------------------------------------------- 
 * ## testimonial slider 
 * ------------------------------------------------------------------- */
.testimonial-slider {
    position: relative;
}

.testimonial-slider p {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: var(--vspace-1);
}

.testimonial-slider__slide {
    position: relative;
}

.testimonial-slider__author {
    display: inline-block;
    min-height: var(--vspace-2);
    margin-bottom: var(--vspace-0_5);
    padding-left: 8.8rem;
    position: relative;
}

.testimonial-slider__avatar {
    width: var(--vspace-2);
    height: var(--vspace-2);
    border-radius: 100%;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
}

.testimonial-slider__cite {
    display: block;
}

.testimonial-slider__cite strong, 
.testimonial-slider__cite span {
    font-style: normal;
}

.testimonial-slider__cite strong {
    font-size: calc(var(--text-size) * 0.944);
    line-height: var(--vspace-0_5);
    color: white;
}

.testimonial-slider__cite span {
    display: block;
    font-size: calc(var(--text-size) * 0.778);
    font-weight: 300;
    line-height: var(--vspace-0_5);
    color: rgba(255, 255, 255, 0.6);
}

/* ------------------------------------------------------------------- 
 * responsive:
 * testimonials
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
    .testimonial-slider__slide {
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .testimonial-slider__author {
        padding-left: 6.8rem;
    }
}

@media screen and (max-width: 400px) {
    .testimonial-slider__author {
        padding-left: 0;
        margin-bottom: var(--vspace-0_5);
        min-height: 0;
        text-align: center;
    }
    .testimonial-slider__avatar {
        display: inline-block !important;
        margin: 0;
        position: static;
    }
}

/* =================================================================== 
 * # preloader
 *
 * ------------------------------------------------------------------- */
 #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    background: #050505;
    z-index: 500;
    height: 100vh;
    width: 100%;
    opacity: 1;
}

.no-js #preloader, .oldie #preloader {
    display: none;
}

#loader {
    width: var(--vspace-1_5);
    height: var(--vspace-1_5);
    padding: 0;
    opacity: 1;
}

#loader:before {
    content: "";
    border-top: 4px solid rgba(255, 255, 255, 0.1);
    border-right: 4px solid rgba(255, 255, 255, 0.1);
    border-bottom: 4px solid rgba(255, 255, 255, 0.1);
    border-left: 4px solid white;
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
    display: block;
    border-radius: 50%;
    width: var(--vspace-1_5);
    height: var(--vspace-1_5);
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ------------------------------------------------------------------- 
 * ## page loaded
 * ------------------------------------------------------------------- */
.ss-loaded #preloader {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .6s .9s ease-in-out;
    transition: all .6s .9s ease-in-out;
}

.ss-loaded #preloader #loader {
    opacity: 0;
    -webkit-transition: opacity .6s ease-in-out;
    transition: opacity .6s ease-in-out;
}

